@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-sider-children {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-modal {
  .ant-modal-header {
    margin-bottom: 18px;
  }
}

.ant-form-vertical {
  .ant-form-item {
    margin-bottom: 16px;
  }
}

.shipper-map {
  display: flex;
  overflow: hidden;
  margin: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  min-height: 70vh;
  border: 1px solid #2c3e50;
  position: relative;

  .shipper-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    // background: #00000011;
    z-index: 2;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 60px;
    }
  }

  .list {
    width: 20%;
    min-width: 280px;
    background: #fff;
    position: relative;
    /* max-height: 100%; */
    max-height: 70vh;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow-y: auto;
      max-height: 70vh;
    }

    li {
      display: block;
      border-bottom: 1px solid #ddd;
      padding: 10px 15px;
      text-decoration: none;
      transition: 0.3s all;

      &.active {
        background: #ffefb3;
        border-bottom-color: #fed232;
      }
    }

    .shipper-item {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      cursor: pointer;
      padding: 12px 16px 16px;
      border-bottom: 1px solid #ccc;

      .shipper-avatar {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          background-position: 50%;
          background-size: cover;
          object-fit: cover;
          border-radius: 50%;
          aspect-ratio: 1;
        }
      }

      .shipper-info {
        padding: 6px 0;

        h4 {
          font-size: 16px;
          font-weight: 700;
          margin: 0;
          margin-bottom: 10px;
        }

        h5 {
          margin: 0;
          color: #555;
        }
      }
    }
  }

  .map {
    width: 80%;

    .map-fullscreen {
      position: absolute;
      top: 0;
      right: 15px;
      padding: 10px;
    }

    // .map-geo {
    //   position: absolute;
    //   top: 36px;
    //   right: 15px;
    //   padding: 10px;
    // }

    .map-nav {
      position: absolute;
      top: 36px;
      right: 15px;
      padding: 10px;
    }

    .shipper-marker {
      &.active {
        animation: zoomInOut 1s ease-in-out infinite;
      }
    }
  }
}

.ant-statistic-content-prefix {
  margin-inline-end: 10px !important;
}

.ant-upload.ant-upload-select {
  overflow: hidden;
}
.avatar-uploader-cover .ant-upload.ant-upload-select {
  width: 200px !important;
}

.loading-photos {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-photo {
  display: flex;
  flex-wrap: wrap;
  .picture {
    cursor: copy;
    width: 104px;
    height: 104px;
    background-color: #eee;
    margin-right: 8px;
    margin-bottom: 8px;
    overflow: hidden;
  
    img {
        width: 100%;
        height: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        object-fit: cover;
    }
  }
}

.chat-wrapper {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
  > * {
    box-sizing: border-box;
  }
  .chat-item {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    &.Shipper {
      justify-content: end;
      flex-direction: row-reverse;
      .chat-content-wrapper  {
        flex-direction: row-reverse;
      }
    }
    &.User {
    }

    .chat-content-wrapper {
      display: flex;
      align-items: center;
      max-width: 70%;
      overflow: hidden;
    .chat-content {
      width: 100%;
      margin: 0 10px;
      border-radius: 10px;
      word-wrap: break-word;
      border: 1px solid #FFD22F;
      overflow: hidden;
      .chat-text,
      .chat-banking {
        padding: 10px;
      }
      .chat-image {
        width: 100%;
        max-height: 1000px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
    .chat-time {
      font-size: 12px;
      color: #999;
      text-align: right;
    }
    }
  }
}
@keyframes zoomInOut {

  0%,
  100% {
    transform: scale(1);
    /* Kích thước bình thường */
  }

  50% {
    transform: scale(1.2);
    /* Kích thước phóng to */
  }
}